
import { defineComponent } from "vue";

import Modal from "@/components/Modal.vue";
import { VERSION } from "../utils/manager/UpdateManager";
import { settings } from "../utils/manager/SettingsManager";
import {
  undo,
  redo,
  history,
  unsavedChange
} from "../utils/manager/HistoryManager";
import {
  exportCurrentProject,
  projectExplorerOpen,
  saveCurrentProject
} from "../utils/manager/ProjectManager";
import { vueRef } from "../utils/VueRef";
import {
  openSyncPrompt,
  SyncStatus,
  syncStatus,
  syncType,
  SyncType
} from "../utils/manager/SyncManager";
import { info } from "../utils/manager/WorkspaceManager";

export default defineComponent({
  components: { Modal },

  data() {
    return {
      settings,
      undo,
      redo,
      history,
      importComponent: false,
      showAbout: false,
      showShortcuts: false,
      showDevMode: false,
      formatVersion: VERSION,
      projectExplorerOpen: vueRef(projectExplorerOpen),
      unsavedChange: vueRef(unsavedChange),
      syncStatus: vueRef(syncStatus),
      SyncStatus,
      syncType: vueRef(syncType),
      SyncType,

      openSyncPrompt,

      saveCurrentProject,
      exportCurrentProject
    };
  },

  methods: {
    exitToExplorer() {
      if (this.unsavedChange) {
        info("Unsaved changes! Save all your changes before you exit.", false, {
          label: "Discard changes and exit",
          callback: () => {
            this.projectExplorerOpen = true;
          }
        });
      } else {
        this.projectExplorerOpen = true;
      }
    }
  }
});
