
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    dragIndication: { type: Boolean }
  },
  data() {
    return {};
  }
});
