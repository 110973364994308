
import { defineComponent } from "vue";
import { PlaceholderCheck } from "@/utils/checks/PlaceholderCheck";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    action: {
      type: Object as () => PlaceholderCheck,
      required: true
    }
  },

  computed: {
    compText(): string {
      switch (this.action.compType) {
        case -1:
          return "text eq";
        case 0:
          return "<";
        case 1:
          return "<=";
        case 2:
          return "=";
        case 3:
          return ">=";
        case 4:
          return ">";
        default:
          return this.action.compType.toString();
      }
    }
  }
});
