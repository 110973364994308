
import { defineComponent, reactive } from "vue";
import DragZone from "./DragZone.vue";
import { ListItem, ListItemGroup } from "@/utils/ListItem";
import { toggleVis, invisibleIDs } from "@/utils/manager/ComponentManager";
import { vueRef } from "../utils/VueRef";

export default defineComponent({
  name: "ComponentList",

  components: { DragZone },

  data() {
    return {
      activeIndex: -2,
      optElem: null as null | ListItem,
      invisibleIDs: vueRef(invisibleIDs),
      toggleVis
    };
  },

  props: {
    components: {
      type: Array as () => ListItem[],
      required: true
    },
    modelValue: {
      type: Object as () => ListItem
    },
    root: {
      type: Boolean,
      default: false
    },
    itemLimit: {
      type: Number
    },
    itemClasses: {
      type: Array as () => string[],
      default: () => []
    },
    treeState: {
      type: Object as () => {
        dragElement: null | ListItem;
        dragElementsList: null | ListItem[];
      },
      default: () =>
        reactive({
          dragElement: null as null | ListItem,
          dragElementsList: null as null | ListItem[]
        })
    }
  },

  mounted() {
    document.addEventListener("click", this.checkClose, { capture: true });
  },

  unmounted() {
    document.removeEventListener("click", this.checkClose, { capture: true });
  },

  computed: {
    hasCapacity(): boolean {
      if (this.itemLimit === undefined) return true;

      let current = this.components.length;
      if (this.activeIndex != -2) current--;

      return current < this.itemLimit;
    }
  },

  methods: {
    dragStart(target: HTMLElement, elem: ListItem, index: number) {
      target.classList.add("dragging");
      this.treeState.dragElement = elem;
      this.treeState.dragElementsList = this.components;
      this.activeIndex = index;
    },

    dragEnd(target: HTMLElement) {
      target.classList.remove("dragging");
      this.treeState.dragElement = null;
      this.treeState.dragElementsList = null;
      this.activeIndex = -2;
    },

    dragDrop(index: number) {
      if (!this.treeState.dragElement) return;

      const oldIndex = this.treeState.dragElementsList?.indexOf(
        this.treeState.dragElement
      );

      if (oldIndex !== undefined && oldIndex != -1)
        this.treeState.dragElementsList?.splice(oldIndex, 1);

      this.components.splice(index, 0, this.treeState.dragElement);
    },

    openMenu(elem: ListItem, ev: MouseEvent) {
      const moreMenu = this.$refs.moreMenu as HTMLElement;
      moreMenu.style.display = "block";
      moreMenu.style.opacity = "0";

      setTimeout(() => {
        let x = ev.x;
        let y = ev.y;
        if (x + moreMenu.offsetWidth > window.innerWidth)
          x = window.innerWidth - moreMenu.offsetWidth - 5;

        if (y + moreMenu.offsetHeight > window.innerHeight)
          y = ev.y - moreMenu.offsetHeight - 5;

        moreMenu.style.top = y + "px";
        moreMenu.style.left = x + "px";
        moreMenu.style.opacity = "1";
      }, 10);

      this.optElem = elem;
    },

    checkClose(ev: MouseEvent) {
      if (ev.target != this.$refs.moreMenu)
        (this.$refs.moreMenu as HTMLElement).style.display = "none";
    },

    duplicateOpt() {
      const index = this.components.indexOf(this.optElem!);
      if (this.optElem && index != -1) {
        const nComp = this.optElem.duplicate();
        if (nComp) {
          this.components.splice(index, 0, nComp);
          this.$emit("update:modelValue", nComp);
        }
      }
    },

    deleteOpt() {
      const index = this.components.indexOf(this.optElem!);
      if (index != -1) {
        this.components.splice(index, 1);
        this.optElem?.delete();
        this.$emit("deleted", this.optElem!);
      }
    },

    visibilityOpt() {
      toggleVis(this.optElem!.id!);
    },

    copyOpt() {
      this.$emit("copy", this.optElem?.toJson());
    },

    addChildOpt(ev: MouseEvent) {
      this.$emit("add-child", {
        event: ev,
        anchor: (this.optElem as ListItemGroup<ListItem>).getItems()
      });
    }
  }
});
