
import { defineComponent } from "vue";
import { images, unregisterImage } from "@/utils/manager/ImageManager";
import { Template } from "@/utils/components/Template";
import { RemoteImage } from "@/utils/components/RemoteImage";

export default defineComponent({
  data() {
    return {
      images,
      unregisterImage,
      inputTransformer: Template.inputTransformer
    };
  },

  props: {
    component: {
      type: Object as () => RemoteImage,
      required: true
    },
    maxHeight: {
      type: Number,
      required: true
    },
    maxWidth: {
      type: Number,
      required: true
    }
  },

  watch: {
    component: {
      deep: true,
      handler() {
        this.ensureBounds();
      }
    }
  },

  computed: {
    gifMode(): boolean {
      return this.component.displayName == "GIF";
    }
  },

  methods: {
    ensureBounds() {
      const bounds = this.component.getBoundingBox();
      bounds.ensureBounds(this.maxWidth, this.maxHeight);
      this.component.modify(bounds);
    }
  }
});
