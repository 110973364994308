
import { defineComponent } from "vue";
import { MetadataAction } from "@/utils/actions/MetadataAction";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    action: {
      type: Object as () => MetadataAction,
      required: true
    }
  }
});
