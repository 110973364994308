
import { defineComponent } from "vue";
import { MessageAction } from "@/utils/actions/MessageAction";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    action: {
      type: Object as () => MessageAction,
      required: true
    }
  }
});
