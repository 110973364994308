
import { defineComponent } from "vue";
import { Image } from "@/utils/components/Image";
import {
  images,
  regImages,
  registerImage,
  unregisterImage
} from "@/utils/manager/ImageManager";
import { Template } from "@/utils/components/Template";
import { GIF } from "@/utils/components/GIF";

export default defineComponent({
  data() {
    return {
      images,
      regImages,
      unregisterImage,
      inputTransformer: Template.inputTransformer
    };
  },

  props: {
    component: {
      type: Object as () => Image | GIF,
      required: true
    },
    maxHeight: {
      type: Number,
      required: true
    },
    maxWidth: {
      type: Number,
      required: true
    }
  },

  watch: {
    component: {
      deep: true,
      handler() {
        this.ensureBounds();
      }
    }
  },

  computed: {
    gifMode(): boolean {
      return this.component.displayName == "GIF";
    }
  },

  methods: {
    ensureBounds() {
      const bounds = this.component.getBoundingBox();
      bounds.ensureBounds(this.maxWidth, this.maxHeight);
      this.component.modify(bounds);
    },

    triggerFileSelector() {
      (this.$refs.fileDownload as HTMLElement).click();
    },

    checkForUpload() {
      const selector = this.$refs.fileDownload as HTMLInputElement;

      if (selector.files?.length) {
        for (const file of selector.files) {
          registerImage(
            file,
            file.name.substr(0, file.name.length - 4),
            this.gifMode
          );
        }
      }
    }
  }
});
