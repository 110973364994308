
import { defineComponent } from "vue";
import { components } from "@/utils/manager/ComponentManager";
import { ViewAction } from "@/utils/actions/ViewAction";
import { View } from "@/utils/components/View";
import { idWatcher } from "@/utils/manager/WorkspaceManager";
import { vueRef } from "../../utils/VueRef";

export default defineComponent({
  data() {
    return {
      components,
      idWatcher: vueRef(idWatcher)
    };
  },

  props: {
    action: {
      type: Object as () => ViewAction,
      required: true
    }
  },

  methods: {
    isChild() {
      const targetComp = this.components[this.action.targetId] as View;

      return (
        targetComp &&
        targetComp.components &&
        targetComp.components.find(comp => comp.id == this.action.activate)
      );
    }
  }
});
