
import { defineComponent } from "vue";
import { ClickAnimation } from "@/utils/components/ClickAnimation";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    component: {
      type: Object as () => ClickAnimation,
      required: true
    }
  }
});
