
import { defineComponent } from "vue";
import { StandbyCheck } from "@/utils/checks/StandbyCheck";

export default defineComponent({
  data() {
    return {};
  },
  props: {
    action: {
      type: Object as () => StandbyCheck,
      required: true
    }
  }
});
