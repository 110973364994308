
import { defineComponent } from "vue";
import { components } from "@/utils/manager/ComponentManager";
import { GifControlAction } from "@/utils/actions/GifControlAction";
import { idWatcher } from "@/utils/manager/WorkspaceManager";
import { vueRef } from "../../utils/VueRef";

export default defineComponent({
  data() {
    return {
      components,
      idWatcher: vueRef(idWatcher)
    };
  },

  props: {
    action: {
      type: Object as () => GifControlAction,
      required: true
    }
  }
});
