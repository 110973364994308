
import { defineComponent } from "vue";
import { MoneyAction } from "../../utils/actions/MoneyAction";
import { Template } from "../../utils/components/Template";

export default defineComponent({
  data() {
    return {
      inputTransformer: Template.inputTransformer
    };
  },

  props: {
    action: {
      type: Object as () => MoneyAction,
      required: true
    }
  }
});
