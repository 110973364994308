
import { defineComponent } from "vue";
import { TextInput } from "@/utils/components/TextInput";
import FontEditor from "@/components/FontEditor.vue";
import ColorInput from "../ColorInput.vue";

export default defineComponent({
  data() {
    return {};
  },

  components: { FontEditor, ColorInput },

  props: {
    component: {
      type: Object as () => TextInput,
      required: true
    },
    maxHeight: {
      type: Number,
      required: true
    },
    maxWidth: {
      type: Number,
      required: true
    }
  },

  watch: {
    component: {
      deep: true,
      handler() {
        this.ensureValues();
      }
    }
  },

  methods: {
    ensureValues() {
      if (this.component.x == undefined) this.component.x = 0;
      if (this.component.y == undefined) this.component.y = 0;
      if (this.component.size == undefined) this.component.size = 0;

      const bounds = this.component.getBoundingBox();
      bounds.ensureBounds(this.maxWidth, this.maxHeight);
      this.component.modify(bounds);
    }
  }
});
