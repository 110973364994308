
import { defineComponent } from "vue";
import {
  deleteProject,
  exportProject,
  getThumbnail,
  importProject,
  licensePromptDoneAction,
  openNewProject,
  openProject,
  projects
} from "../utils/manager/ProjectManager";
import { VERSION } from "../utils/manager/UpdateManager";
import { info, loading } from "../utils/manager/WorkspaceManager";
import { Project } from "../utils/Project";
import { vueRef } from "../utils/VueRef";
import AbsoluteMenu from "./AbsoluteMenu.vue";

export default defineComponent({
  data() {
    return {
      projects: vueRef(projects),
      licensePromptDoneAction: vueRef(licensePromptDoneAction),
      openNewProject,
      openProject,
      VERSION,
      info
    };
  },

  components: { AbsoluteMenu },

  computed: {
    summedSize(): number {
      return this.projects
        .map(p => (JSON.stringify(p).length * 2) / 1000 / 1000)
        .reduce((i, j) => i + j, 0);
    }
  },

  watch: {
    projects: {
      handler() {
        setTimeout(() => {
          this.projects.forEach(async project => {
            const elem = this.$refs[`proj#${project.name}`] as HTMLElement;
            if (elem)
              elem.style.backgroundImage = `url(${await getThumbnail(
                project.name
              )})`;
          });
        }, 30);
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    triggerFileSelector() {
      (this.$refs.fileDownload as HTMLElement).click();
    },

    async checkForUpload() {
      const selector = this.$refs.fileDownload as HTMLInputElement;

      if (selector.files?.length) {
        const file = selector.files[0];
        loading(true);

        const project = JSON.parse(await file.text()) as Project;
        await importProject(project);

        loading(false);
      }
    },

    getActions(project: Project) {
      const actions = [
        {
          icon: "download",
          name: "Download",
          action: () => exportProject(project.name)
        },
        {
          icon: "delete",
          name: "Delete",
          action: () => deleteProject(project.name)
        }
      ];

      if (project.version != VERSION) {
        actions.splice(0, 0, {
          icon: "update",
          name: "Open to update",
          action: () => {
            openProject(project);
          }
        });
      }

      return actions;
    }
  }
});
