<template>
  <div class="settings-row">
    <span class="label">This component has no specific settings</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {};
  }
});
</script>

<style lang="scss" scoped></style>
